import React from 'react';
import classNames from 'classnames';
import styles from './Footer.module.scss';

export interface Props {}

export default () => (
    <footer className={styles.footer}>
        <div className={classNames('container')}>
            <div className={styles['footer-content']}>
                <div className={styles.footer__text}>
                    <img
                        src="/assets/images/logo-white.png"
                        className={styles.logo}
                        alt="solstein logo"
                    />
                    <div>
                        <p>Solsten is a company based in the US and Germany.</p>{' '}
                        <p>
                            We are the only company in the world that understands human beings,
                            anonymously, and at scale to empower companies to make decisions based
                            on the needs of their customers, resulting in experiences that better,
                            healthier, livelier, and more engaging.
                        </p>{' '}
                        <p>We believe that technology is in service of humankind.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.footer__menu}>
            <nav>
                <ul>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://solsten.io/">
                            Company Website
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://solsten.io/terms-of-service/"
                        >
                            Terms of service
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://solsten.io/privacy-policy/"
                        >
                            Privacy policy
                        </a>
                    </li>
                </ul>
            </nav>
            <div className={styles['footer-info']}>© 2022 Solsten, Inc. All Rights Reserved.</div>
        </div>
    </footer>
);
